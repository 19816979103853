<template>
  <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />
        <h1 class="user__title">{{ $t('resto.user_profile_title')}}</h1>
      </header>

      <!-- Loading -->
      <div
        v-if="loading || deleteLoading"
        class="user__loader">
        <ui-loader />
      </div>

      <!-- Body -->
      <div
        v-if="!loading && !deleteLoading"
        class="user__body flow">

        <div class="-cols">
          <div class="col">
            <!-- Information -->
            <form
              class="view-user-profile__content flow"
              @submit="onSubmit">
              <div class="group">
                <!-- Firstname -->
                <forms-input
                  autofocus
                  :errors="getErrors('firstname')"
                  :placeholder="$t('resto.checkout_review_firstname_placeholder')"
                  type="text"
                  v-model="edition.firstname"
                >{{ $t('resto.checkout_review_firstname') }}</forms-input>

                <!-- Lastname -->
                <forms-input
                  :errors="getErrors('lastname')"
                  type="text"
                  :placeholder="$t('resto.checkout_review_firstname_placeholder')"
                  v-model="edition.lastname"
                >{{ $t('resto.checkout_review_lastname') }}</forms-input>
              </div>

              <div class="group">
                <!-- Email -->
                <forms-input
                  :errors="getErrors('email')"
                  type="text"
                  :placeholder="$t('resto.checkout_review_email_placeholder')"
                  v-model="edition.email"
                >{{ $t('resto.checkout_review_email') }}</forms-input>
              </div>

              <div class="group">
                <!-- Phone -->
                <forms-input
                  :errors="getErrors('phone')"
                  :placeholder="$t('resto.checkout_review_phone_placeholder')"
                  type="text"
                  v-model="edition.phone"
                >{{ $t('resto.checkout_review_phone') }}</forms-input>

                <!-- Birthdate -->
                <!-- :placeholder="$t('resto.checkout_review_phone_placeholder')" -->
                <forms-input
                  :errors="getErrors('birthdate')"
                  :hint="birthdateHint"
                  type="date"
                  v-model="edition.birthdate"
                >{{ $t('resto.user_birthdate') }}</forms-input>
              </div>

              <!-- Newsletter optin -->
              <div class="separator">
                <forms-checkbox
                  :checked="edition.opt_in"
                  v-model="edition.opt_in"
                >{{ $t('resto.checkout_review_optin') }}</forms-checkbox>
              </div>

              <!-- Actions -->
              <footer class="user__actions">
                <actions-button
                  :appearance="$pepper.Appearance.PRIMARY"
                  class="fill"
                  @click="onUpdateUser"
                >{{ $t('resto.action_confirm') }}</actions-button>

                <actions-button
                  :appearance="$pepper.Appearance.LINK"
                  class="center"
                  :intent="$pepper.Intent.DANGER"
                  :size="$pepper.Size.S"
                  @click="triggerDeleteAccount"
                >{{ $t('resto.action_delete_account') }}</actions-button>
              </footer>
            </form>
          </div>

          <div class="col">
            <form
              class="view-user-profile__content flow user-card"
              @submit="onSubmit">
              <!-- Current password -->
              <forms-input
                :errors="getErrors('password')"
                @iconClick="() => onChangePasswordVisible('currentPasswordFieldType')"
                :icon-post="currentPasswordFieldIcon"
                :icon-post-interactive="true"
                name="current-password"
                :placeholder="$t('resto.user_password_placeholder')"
                ref="current-password"
                required
                :type="currentPasswordFieldType"
                v-model="currentPassword"
              >{{ $t('resto.user_current_password') }}</forms-input>

              <!-- New password -->
              <forms-input
                :errors="getErrors('new_password')"
                @iconClick="() => onChangePasswordVisible('newPasswordFieldType')"
                :icon-post="newPasswordFieldIcon"
                :icon-post-interactive="true"
                name="new-password"
                :placeholder="$t('resto.user_password_placeholder')"
                ref="new-password"
                required
                :type="newPasswordFieldType"
                v-model="newPassword"
              >{{ $t('resto.user_password') }}</forms-input>

              <!-- New password confirmation -->
              <forms-input
                :errors="getErrors('new_password_confirmation')"
                @iconClick="onChangePasswordVisible('newPasswordConfirmationFieldType')"
                :icon-post="newPasswordConfirmationFieldIcon"
                :icon-post-interactive="true"
                name="new-password-confirmation"
                :placeholder="$t('resto.user_password_confirmation_placeholder')"
                ref="new-password-confirmation"
                required
                :type="newPasswordConfirmationFieldType"
                v-model="newPasswordConfirmation"
              >{{ $t('resto.user_password_confirmation') }}</forms-input>

              <!-- Actions -->
              <footer class="user__actions">
                <actions-button
                  :intent="$pepper.Intent.DANGER"
                  class="fill"
                  @click="onUpdatePassword"
                >{{ $t('resto.action_update_password') }}</actions-button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'
import MixinError from '@/helpers/errors'

export default {
  name: 'UserProfile',

  components: {
    NavigationsBreadcrumb,
  },

  inject: [
    '$user',
    '$loyalty',
    '$localStorage',
  ],

  mixins: [ MixinError ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('resto.user_account_title'), href: {name: 'sayl-front-user.home'} }, //Your account',
        { label:  this.$t('resto.user_profile_title'), href: '#' }
      ],
      errors: {},
      edition: {},
      editPassword: false,

      emailPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      loading: false,

      currentPassword: null,
      currentPasswordFieldType: 'password',

      newPassword: null,
      newPasswordFieldType: 'password',

      newPasswordConfirmation: null,
      newPasswordConfirmationFieldType: 'password',

      deleteLoading: false
    }
  },

  computed: {
    birthdateHint() {
      if(this.hasLoyalty) {
        let birthdayReward = this.$basil.get(this.$loyalty, 'program.rules.birthdayReward', 0)
        if(birthdayReward != null && birthdayReward > 0) {
          return this.$t('resto.user_birthday_reward_loyalty_hint', { value: birthdayReward, symbol: this.$basil.get(this.$loyalty, 'program.points.symbol') })
        }
      }
      return null
    },

    currentPasswordFieldIcon() {
      return this.currentPasswordFieldType == 'password' ? 'visible' : 'hidden'
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false
      
      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        !this.isModeTt,
        !isShopExcluded
      ].filter(r => r === false).length === 0
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin'
    },

    newPasswordFieldIcon() {
      return this.newPasswordFieldType == 'password' ? 'visible' : 'hidden'
    },

    newPasswordConfirmationFieldIcon() {
      return this.newPasswordConfirmationFieldType == 'password' ? 'visible' : 'hidden'
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    isFieldEmailValid(){
      let value = this.$basil.get(this.edition, 'email', '') || ''
      let hasValue = value.trim().length > 0
      let isValid = this.emailPattern.test(value)

      if(!hasValue) {
        this.errors['email'] = [this.$t(`resto.field_is_required`)]
      }

      if(hasValue && !isValid) {
        this.errors['email'] = [this.$t(`resto.email_not_correct`)]
      }

      return hasValue && isValid
    },

    onReset() {
      this.edition = {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        email: this.user.email,
        phone: this.user.phone,
        opt_in: this.user.optIn === true ? true : false,
        opt_in_tc: false,
        birthdate: this.user.birthdate,
      };

      this.currentPassword = null;
      this.newPassword = null;
      this.newPasswordConfirmation = null;
      this.errors = {};
    },

    onChangeEditPassword() {
      this.onReset()
    },

    onChangePasswordVisible(field) {
      this[field] = this[field] === 'password' ? 'text' : 'password';
      this.key++;
    },

    onUpdatePassword() {
      this.errors = {}
      this.loading = true

      this.$user.updatePassword({ password: this.currentPassword, newPassword: this.newPassword, newPasswordConfirmation: this.newPasswordConfirmation })
        .then(() => this.onReset())
        .catch((e) => this.handleErrors(e))
        .finally(() => this.loading = false)
    },

    onUpdateUser() {
      this.errors = {}
      this.loading = true

      if(!this.isFieldEmailValid()){
        this.loading = false
        return
      }

      this.$user.update({ user: this.edition })
        .then(() => this.onReset())
        .catch((e) => this.handleErrors(e))
        .finally(() => this.loading = false)
    },

    onSubmit(e) {
      e.preventDefault()
      return
    },

    triggerDeleteAccount() {
      this.$confirm({
        title: this.$t('resto.delete_account_title'),
        description: this.$t('resto.delete_account_description'),
        primaryAction: this.$t('resto.confirm'),
        secondaryAction: this.$t('resto.cancel'),
        onPrimary: this.onConfirmDelete
      })
    },

    async onConfirmDelete() {
      try {
        this.deleteLoading = true
        await this.$user.anonymize()
        window.location.reload()
      } catch(e) {
        $console.error(e)
        this.deleteLoading = false
      }
    }
  },

  mounted() {
    if(!this.$basil.get(this.user, 'isLogged')) {
      this.$router.replace({name: 'sayl-front-user.login'}).catch(() => {})
      return
    }

    this.onReset()
  },
}
</script>
